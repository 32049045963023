export class Captcha
{
    constructor(options = {})
    {
        this.options = Object.assign({'sitekey': ''}, options);
    }

    render(elm, options = {})
    {
        if (!this.isEnabled() || !elm || elm.innerHTML.length !== 0) {
            // Captcha not enabled, grecaptcha -library not loaded, captcha element not found or already rendered
            return;
        }

        options = Object.assign(this.options, options);

        // Return the widget ID
        return window.grecaptcha.render(elm, options);
    }

    execute(widgetId = null)
    {
        if (!this.isEnabled) {
            // Captcha not enabled or grecaptcha -library not loaded
            return false;
        }

        window.grecaptcha.execute(widgetId);

        return true;
    }

    reset(widgetId = null)
    {
        if (!this.isEnabled()) {
            return false;
        }

        window.grecaptcha.reset(widgetId);

        return true;
    }

    isEnabled()
    {
        // Captcha not enabled or grecaptcha -library not loaded
        return typeof window.grecaptcha !== 'undefined';
    }
}
