export class LoaderElement
{
    static appendTo(elm: HTMLElement, ballCount: number = 5): HTMLElement
    {
        let loader: HTMLElement = document.createElement('div');

        loader.classList.add('loading-animation');
        elm.appendChild(loader);

        for (let i = 0; i < ballCount; i++) {
            let ball = document.createElement('i');
            loader.appendChild(ball);
        }

        return loader;
    }
}
