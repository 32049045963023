import {AjaxLoadMore} from './AjaxLoadMore.js';

export class LoadMoreButton
{
    static create(options = {})
    {
        if (!(options instanceof Object)) {
            console.warn('LoadMoreButton requires options{} to work.');
            return;
        }

        let defaults = {
            'button': null,
            'container': null,
            'id': null,
            'url': null,
            'childSelector': null,
            'afterLoad': null,
            'beforeLoad': null,
            'append': true,
            'bindEventsElement': null,
            'cloneLoadMoreButton': false,
            'offset': null,
            'limit': null,
            'requestData': null,
        };

        let settings = Object.assign(defaults, options);

        // Default afterload function / custom
        settings.afterLoad = typeof settings.afterLoad === "function" ?
            settings.afterLoad :
            (loader, update, hasMore) => {
                if (!hasMore) {
                    settings.button.remove();
                    delete settings.container.loader;
                }
                if (update) {
                    if(settings.bindEventsElement instanceof HTMLElement) {
                        window.bo.events.bindAll(settings.bindEventsElement);
                    } else {
                        window.bo.events.bindAll(settings.container);
                    }
                }
            };

        // Init
        settings.container.loader = new AjaxLoadMore(settings);
    }
}