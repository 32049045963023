export class Ajax
{
    constructor(options = {}, headers = {})
    {
        this.ajaxOptions = Object.assign({
            'method': 'GET',
            'url': '',
            'data': null,
            'timeout': 0,
            'loadFunction': null,
            'timeoutFunction': null,
            'errorFunction': null,
            'loadendFunction': null,
            'cache': false,
            'contentType': null,
            'xhr': null
        }, options);

        this.ajaxHeaders = Object.assign({
            'X-Requested-With': 'XMLHttpRequest'
        }, headers);
    }

    get(url, options = {}, headers = {})
    {
        options = Object.assign({
            'url': url
        }, options);

        headers = Object.assign({}, this.ajaxHeaders, headers);

        return this.ajax(options, headers);
    }

    post(url, data = {}, options = {}, headers = {})
    {
        options = Object.assign({
            'method': 'POST',
            'url': url,
            'data': data
        }, options);
        headers = Object.assign({}, this.ajaxHeaders, headers);

        return this.ajax(options, headers);
    }

    ajax(options = {}, headers = {})
    {
        options = Object.assign({}, this.ajaxOptions, options);

        if (!options.cache) {
            headers = Object.assign(headers, {
                'Cache-Control': 'no-cache, max-age=0'
            });
        }

        let xhr = new XMLHttpRequest();
        xhr.timeout = options.timeout;
        xhr.open(options.method, options.url);

        for (let key in headers) {
            xhr.setRequestHeader(key, headers[key]);
        }

        if (options.contentType !== null) {
            xhr.setRequestHeader('Content-Type', options.contentType);
        }

        // Run always
        this.onEnd = function(fn) {
            xhr.addEventListener('loadend', () => {
                let response = JSON.parse(xhr.responseText);
                if (response && response instanceof Object && 'data' in response) {
                    if ('data-ga-event' in response.data) {
                        window.bo.tagManagerTrigger.push({
                            'event': response.data['data-ga-event']
                        });
                    }
                }
                fn(xhr);
            });

            return this;
        };
        if (typeof options.loadendFunction === 'function') {
            this.onEnd(options.loadendFunction);
        }

        // OnLoad
        this.onLoad = function(fn) {
            xhr.addEventListener('load', () => {
                if (xhr.status !== 200) {
                    return;
                }
                fn(xhr);
            });

            return this;
        };
        if (typeof options.loadFunction === 'function') {
            this.onLoad(options.loadFunction);
        }

        // OnTimeout
        this.onTimeout = fn => {
            xhr.addEventListener('timeout', () => {
                console.warn('AJAX timeout');
                fn(xhr);
            });

            return this;
        };
        if (typeof options.timeoutFunction === 'function') {
            this.onTimeout(options.timeoutFunction);
        }

        // OnError
        this.onError = fn => {
            xhr.addEventListener('loadend', () => {
                if (xhr.status === 200 || xhr.status === 0) {
                    return;
                }

                fn(xhr);
            });

            return this;
        };

        if (typeof options.errorFunction === 'function') {
            this.onError(options.errorFunction);
        }

        this.getXhrObject = () => {
            return xhr;
        };

        // Customizable XHR-object
        if (typeof options.xhr === 'function') {
            xhr = options.xhr(xhr);
        }

        if (options.contentType === null && options.data !== null && typeof options.data.append !== 'function') {
            // Not FormData... Maybe a regular object? Convert to FormData.
            let data = new FormData();
            Object.keys(options.data).forEach(key => data.append(key, options.data[key]));
            options.data = data;
        }

        xhr.send(options.data);

        return this;
    }

    postRedirect(url)
    {
        let newForm = document.createElement('form');
        newForm.action = url;
        newForm.method = 'post';
        newForm.style.display = 'none';
        document.body.appendChild(newForm);
        newForm.submit();
    }
}
