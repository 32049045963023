export class AjaxLoadMore
{
    constructor(opt)
    {
        if (!(opt instanceof Object)) {
            return;
        }

        let defaults = {
            'url': null,
            'button': null,
            'container': null,
            'id': null,
            'childSelector': null,
            'limit': 10,
            'afterLoad': null,
            'beforeLoad': null,
            'append': true,
            'cloneLoadMoreButton': false,
            'clone': null,
            'offset': null,
            'bindEventsElement': null,
            'calcOffset': null,
            'requestData': null,
        };

        this.settings = Object.assign(defaults, opt);

        if (!this.settings.url ||
            !(this.settings.button instanceof HTMLElement) ||
            !(this.settings.container instanceof HTMLElement) ||
            !this.settings.id) {
            console.warn('Cant init LoadMore function: missing settings..');
            return;
        }

        // Create url
        this.settings.ajaxUrl = window.config.localePrefix + this.settings.url + this.settings.id + '/';
        this.settings.button.addEventListener('click', () => {
            this.loadMore();
        });

        // Clone
        if (this.settings.cloneLoadMoreButton) {
            this.settings.clone = this.settings.button.parentNode.cloneNode(true);
        }
    }

    loadMore()
    {
        // The value can change now with the addition of the media library search parameters.
        this.settings.requestData = this.settings.button.dataset.json;
        let parent = this.settings.container;
        let elements;

        if (!this.settings.childSelector) {
            elements = parent.children;
        } else {
            elements = parent.querySelectorAll(this.settings.childSelector);
        }

        let fd = new FormData();
        if (this.settings.limit) {
            fd.append('limit', this.settings.limit);
        }

        if (this.settings.requestData) {
            fd.append('requestData', this.settings.requestData);
        }

        fd.append('offset', this.settings.offset || elements.length);

        if (typeof this.settings.beforeLoad === 'function') {
            this.settings.beforeLoad(this);
        }

        window.bo.ajax.post(this.settings.ajaxUrl, fd).onLoad(xhr => {
            let json = JSON.parse(xhr.responseText);
            if('data' in json) {
                let newElements = json.data.content || json.data.html;
                let update;
                let hasMore = 'hasMore' in json.data ? json.data.hasMore : null;
                let mediaLibrary = document.querySelector('.evt-media-library');

                // Append, rebinding events is necessary
                if (newElements.length) {
                    if (this.settings.cloneLoadMoreButton) {
                        this.settings.button.parentNode.remove();
                    }
                    if (this.settings.append) {
                        parent.innerHTML = parent.innerHTML + newElements;
                    } else {
                        parent.innerHTML = newElements + parent.innerHTML;
                    }
                    if (this.settings.cloneLoadMoreButton && hasMore) {
                        parent.appendChild(this.settings.clone);
                    }
                    
                    if (mediaLibrary) {
                        let updatedJson = [...JSON.parse(mediaLibrary.querySelector('script').innerText), ...json.data.results];
                        mediaLibrary.mediaJson = updatedJson;
                        mediaLibrary.querySelector('script').innerText = JSON.stringify(updatedJson);
                    }

                    update = true;
                } else {
                    update = false;
                }

                if (typeof this.settings.calcOffset === 'function') {
                    this.settings.offset = this.settings.calcOffset(this.settings.offset);
                }

                if (typeof this.settings.afterLoad === 'function') {
                    this.settings.afterLoad(this, update, hasMore);
                }
            } else {
                console.warn('AjaxLoadMore: No data found from response');
            }
        });
    }
}