if (typeof Element.prototype.hide !== 'function') {
    Element.prototype.hide = function() {
        this.style.display = 'none';
    };
}

if (typeof Element.prototype.show !== 'function') {
    Element.prototype.show = function(type) {
        this.style.display = type ? type : 'block';
    };
}

if (typeof Element.prototype.scrollTo !== 'function') {
    Element.prototype.scrollTo = function(y, x) {
        if (y) {
            this.scrollTop = y;
        }
        if (x) {
            this.scrollLeft = x;
        }
    };
}