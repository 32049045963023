interface ToastOptions {
    displayTime: number;
    fadeTime: number;
}

export class Toast
{
    readonly options: ToastOptions;

    constructor(options: ToastOptions)
    {
        this.options = Object.assign({
            'displayTime': 3000,
            'fadeTime': 2000,
        }, options);
    }

    success(message: string, title: string = '', options: object = {}): void
    {
        this._show('success', message, title, options);
    }

    info(message: string, title: string = '', options: object = {}): void
    {
        this._show('info', message, title, options);
    }

    warning(message: string, title: string = '', options: object = {}): void
    {
        this._show('warning', message, title, options);
    }

    error(message: string, title: string = '', options: object = {}): void
    {
        this._show('error', message, title, options);
    }

    _show(type: string, message: string, title: string, options: object = {}): void
    {
        let that = this;

        let finalOptions = Object.assign({}, this.options, options);

        let toastRoot = document.getElementById('toast-root');
        if (toastRoot === null) {
            toastRoot = document.createElement('div');
            toastRoot.id = 'toast-root';
            document.body.appendChild(toastRoot);
        }

        let toast: HTMLElement = document.createElement('div');
        toast.classList.add('toast', type);

        let toastContent = document.createElement('div');
        toastContent.classList.add('toast-content');
        toast.appendChild(toastContent);

        if (title !== '') {
            let toastTitle: HTMLElement = document.createElement('h3');
            toastTitle.innerHTML = title;
            toastContent.appendChild(toastTitle);
        }

        let toastMessage = document.createElement('p');
        toastMessage.innerHTML = message;
        toastContent.appendChild(toastMessage);

        toastRoot.appendChild(toast);

        toast.addEventListener('click', function() {
            removeToast();
        });

        let removeToast = (): void => {
            toast.remove();

            if (toastRoot?.querySelector('.toast') === null) {
                toastRoot.remove();
            }
        };

        let startFade = (): void => {
            toast.classList.add('fading');
            toast.style.transitionDuration = that.options.fadeTime / 1000 + 's';
        };

        let fading = setTimeout(startFade, finalOptions.displayTime);
        let removing = setTimeout(removeToast, finalOptions.displayTime + finalOptions.fadeTime);

        toast.addEventListener('mouseover', () => {
            clearTimeout(fading);
            clearTimeout(removing);
            toast.classList.remove('fading')
            toast.style.transitionDuration = '';
        });

        toast.addEventListener('mouseout', () => {
            fading = setTimeout(startFade, finalOptions.displayTime);
            removing = setTimeout(removeToast, finalOptions.displayTime + finalOptions.fadeTime);
        });
    }
}
