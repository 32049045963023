// #f5f5f5
// 1x1 "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8+h8AAu8B9totwrcAAAAASUVORK5CYII=";
// 1x1 - transparent "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
// 13x2 data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAACCAQAAAAptJ4iAAAAEUlEQVR42mP8+p8BB2DELQUAmNUD6y0ek9YAAAAASUVORK5CYII=
// 28x9 "data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAJCAQAAACLH4Q3AAAAFElEQVR42mP8+p+BLMA4qnFYaAQAhFwRnm2pZIMAAAAASUVORK5CYII=";
// 35x20 "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAUCAQAAACtr0zHAAAAHElEQVR42mP8+p+BCoBx1JhRY0aNGTVm1BjyAQDMhSclOJt3ZQAAAABJRU5ErkJggg=="

export class Lazyload
{
    constructor(parent = document)
    {
        let lazyStyles = [...parent.querySelectorAll('[data-lazy-style]:not(.loaded)')];
        let observer = null;

        if (!lazyStyles.length) {
            console.warn('Lazyload => No lazies found');
            return;
        }

        // Observer config
        let config = {
            root: null, // relative to document viewport
            rootMargin: '0px 0px 200px 0px', // margin around root. Values are similar to css property. Unitless values not allowed
            threshold: 0 // visible amount of item shown in relation to root
        };

        let loadLazyStyle = (target) => {
            target.setAttribute('style', target.dataset.lazyStyle);
            target.removeAttribute('data-lazy-style');
            target.classList.add('loaded');
        };

        // Observer init / if not supported loads all images straight away
        if ('IntersectionObserver' in window &&
            'isIntersecting' in window.IntersectionObserverEntry.prototype) {
            let onChange = (changes, observer) => {
                for (let change of changes) {
                    if (change.isIntersecting) {
                        if (change.target.hasAttribute('data-lazy-style')) {
                            loadLazyStyle(change.target);
                        }
                        observer.unobserve(change.target);
                    }
                }
            };

            observer = new IntersectionObserver(onChange, config);

            for (let lazy of lazyStyles) {
                observer.observe(lazy);
            }
        } else {
            // Support for Safari and other great browsers...
            console.warn('Lazyload => Browser not supporting IntersectionObserver');
            for (let lazy of lazyStyles) {
                loadLazyStyle(lazy);
            }
        }
    }
}